import React, { useRef, useState, useEffect } from 'react';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import useDetectPrint from '../../hooks/useDetectPrint';
import Doughnut from './doughnut';

const DoughnutWrapper = ({ value, negative, text, smallText, neutral = false }) => {
  const isPrinting = useDetectPrint();
  const ref = useRef(null);
  const animate = useRef(0);
  const entry = useIntersectionObserver(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const isVisible = !!entry?.isIntersecting || isPrinting;
  const [number, setNumber] = useState(0);
  useEffect(() => {
    if (isPrinting) {
      setNumber(value);
      return;
    }
    if (isVisible) {
      window?.clearInterval(animate.current);
      number != value
        ? (animate.current = window?.setInterval(() => {
            setNumber((val) => (val < value ? val + 1 : value));
          }, 15))
        : window?.clearInterval(animate.current);
    } else {
      window?.clearInterval(animate.current);
      animate.current =
        number != 0
          ? window?.setInterval(() => {
              setNumber((val) => (val > 0 ? val - 1 : 0));
            }, 15)
          : window?.clearInterval(animate.current);
    }
    () => {
      window?.clearInterval(animate.current);
    };
  }, [value, isVisible]);

  return (
    <div
      ref={ref}
      className="relative flex flex-col justify-center items-center flex-1 h-full aspect-1 px-7"
    >
      <div className="relative flex flex-col justify-center items-center z-10">
        <span
          className={`font-black ${
            neutral ? 'text-gray-600' : negative ? 'text-brand-green-5' : 'text-brand-yellow-5'
          } ${smallText ? 'text-2xl sm:text-3xl' : 'text-3xl sm:text-5xl'}`}
        >
          {`${(number ? number : 0) + '%'}`}
        </span>
        <span
          className={`text-xs text-center font-light ${
            neutral ? 'text-gray-600' : negative ? 'text-brand-green-5' : 'text-brand-yellow-5'
          } ${smallText ? '' : 'sm:text-base'}`}
        >
          {text}
        </span>
      </div>
      <div className="absolute inset-0 z-0">
        <Doughnut value={value} negative={negative} neutral={neutral} animate={isVisible} />
      </div>
    </div>
  );
};
export default DoughnutWrapper;
