import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import BackButton from './backButton';
import Language from './content/language';
import { SmallerBlueArrow } from './layout/arrows';
import arrowDecorator from '../images/icons/icon-arrows-decorator.svg';

const ContentIntro = ({
  pathname,
  headerStyle,
  title,
  subTitle,
  introText,
  dateModified,
  heroImage,
  heroImageAlt,
  postType,
  showLang,
  currentLang,
  usedLangs,
}) => {
  return (
    <>
      {showLang && (
        <div className="w-full max-w-7xl mx-auto">
          <Language
            pathname={pathname}
            currentLang={currentLang}
            usedLangs={usedLangs}
            className={'relative justify-end -mb-4 lg:mb-3 z-10'}
          />
        </div>
      )}
      <div
        className={`not-prose relative flex flex-col gap-5 w-full max-w-7xl mx-auto lg:gap-12 ${
          headerStyle === 'vertical' ? '' : 'lg:flex-row'
        }`}
      >
        {headerStyle === 'generic' ? (
          <div className="w-full flex flex-col justify-start items-start pt-1 sm:pt-5 border-b border-b-brand-gray-2">
            <SmallerBlueArrow>
              <h1 className="text-left pr-5 text-2xl font-bold capitalize mb-4 text-brand-blue-5 pt-2 sm:text-4xl sm:font-black sm:mb-5 sm:pt-0 md:text-5xl lg:text-6xl">
                {title || ''}
              </h1>
            </SmallerBlueArrow>
            {subTitle && (
              <p
                className="text-left text-sm font-light mb-4 text-gray-700 w-full max-w-3xl sm:text-xl sm:mb-5"
                dangerouslySetInnerHTML={{ __html: subTitle || '' }}
              />
            )}
          </div>
        ) : (
          <>
            <div
              className={`w-full my-auto ${
                headerStyle === 'vertical' ? 'max-w-3xl mx-auto' : 'lg:w-1/2'
              }`}
            >
              <div className="flex justify-between items-center w-max mb-2.5">
                <BackButton
                  onClick={() => navigate(`/${postType}/`)}
                  text={postType === 'campaigns' ? 'back to all campaigns' : 'back'}
                />
              </div>
              <div
                className="flex flex-col gap-2.5 border-b border-slate-200 pb-5"
                data-top-section
              >
                <h1 className="text-left text-2xl font-bold capitalize text-brand-blue-5 pt-2 sm:text-5xl sm:font-black sm:pt-0 lg:pr-4">
                  {title}
                </h1>
                {subTitle && !headerStyle === 'vertical' && (
                  <div>
                    <h3 className="font-semibold text-brand-gray-5 mb-1 text-lg sm:font-bold md:text-xl lg:text-2xl">
                      {subTitle}
                    </h3>
                    <img
                      src={arrowDecorator}
                      width={41}
                      height={13}
                      className="mb-2.5"
                      alt="Arrow Decorator"
                    />
                  </div>
                )}
                {introText && (
                  <span className="text-base text-gray-700 font-light">{introText}</span>
                )}
                {dateModified && (
                  <h4 className="text-left text-xs italic font-light text-gray-700">
                    Last Updated: {dateModified}
                  </h4>
                )}
              </div>
            </div>
            {heroImage ? (
              <div
                className={`w-full flex flex-col gap-2 ${
                  headerStyle === 'vertical' ? 'max-w-6xl mx-auto' : 'lg:w-1/2'
                }`}
              >
                <GatsbyImage
                  image={heroImage}
                  alt={heroImageAlt ? heroImageAlt : title}
                  className="shadow-lg object-cover w-full mx-auto rounded-sm overflow-hidden isolate"
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default ContentIntro;
